import React from "react";
import IconCloud from "./IconCloud";
import heroImage from "../assets/img/hero-1.png";
import Learing from "../assets/img/learning.png";
import Interview from "../assets/img/Interview.png";
import Internship from "../assets/img/intenship.png";
import { FaExternalLinkAlt } from 'react-icons/fa';

const slugs = [
  "html", "css", "bootstrap", "javascript", "typescript", "react", "angular", "vue", "css3", "sass", "tailwindcss", "nodedotjs", "java",
  "python", "php", "express", "spring", "django", "laravel", "aspnet", "restapi", "graphql", "webpack", "babel", "vite", "postgresql", "mysql",
  "mariadb", "mongodb", "redis", "firebase", "AWS",
];

const IconCloudDemo = ({ darkMode }) => (
  <main>
    <section id="home" className={`container py-5 text-${darkMode ? 'light' : 'dark'}`}>
      <header className="text-center">
        <h1 className="mt-4 fs-2" style={{ fontFamily: 'Times New Roman, serif', position: 'relative', display: 'inline-block' }}>
          Empower Your Career with <strong>InternSteps</strong>
          <div style={{ height: '2px', background: 'linear-gradient(to right, #ff007f, #7f00ff)', width: '100%', position: 'absolute', left: '0' }}></div>
        </h1>
        <p className={`mb-3 ${darkMode ? 'text-light' : 'text-dark'}`} style={{ fontSize: '1.1rem', lineHeight: '1.5' }}>Find exciting opportunities and accelerate your career path.</p>
      </header>

      <div className="row align-items-center justify-content-center">
        {/* Hero Image */}
        <div className="col-md-4">
          <img className="img-fluid" src={heroImage} alt="Empowering Career Opportunities" style={{ height: '300px', objectFit: 'cover' }} />
        </div>

        {/* IconCloud */}
        <div className="col-md-4 d-flex justify-content-center align-items-center">
          <IconCloud iconSlugs={slugs} style={{ height: '100px' }} />
        </div>

        {/* Cards Section */}
        <div className="col-md-6 col-lg-4 d-flex justify-content-center" style={{ height: '360px' }}>
          <section id="home" className="mt-5">
            {[{ name: 'Learning Path', imgSrc: Learing, link: 'tutorial-box', color: '#ADD8E6' }, // Light Blue
            { name: 'Interview Prep', imgSrc: Interview, link: 'interview-preparation', color: '#90EE90' }, // Light Green
            { name: 'Internship Now', imgSrc: Internship, link: 'about-internship', color: '#FFA500' } // Light Orange
            ].map((card, index) => (
              <div className="card mb-3 border-primary blink-card" key={index} style={{
                backgroundColor: 'transparent',
                borderColor: 'rgba(0, 123, 255, 0.5)', // Semi-transparent border
                boxShadow: 'none', // Remove any shadow for transparency effect
              }}>
                <div className="card-body d-flex align-items-center">
                  <img src={card.imgSrc} alt={card.name} className="blink-image" style={{ height: '50px', maxWidth: '100px' }} />
                  <a href={card.link} className="btn btn-sm fw-bold text-primary blink-button" style={{ padding: '8px 15px', fontSize: '0.9rem' }} target="_blank" rel="noopener noreferrer">
                    {card.name}
                    <FaExternalLinkAlt className="ms-2" />
                  </a>
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
    </section>

    {/* Styles for blinking animation */}
    <style>
      {`
        /* Blinking animation */
        @keyframes blink {
          0% { opacity: 1; color: #007bff; }
          50% { opacity: 0; color: #ff007f; }
          100% { opacity: 1; color: #7f00ff; }
        }

        /* Apply blink effect to the card text */
        .blink-button {
          animation: blink 3s infinite;
          color: #007bff;
        }

        /* Apply blink effect to the button */
        .blink-button {
          animation: blink 3s infinite;
        }
      `}
    </style>
  </main>
);

export default IconCloudDemo;
