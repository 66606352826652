import React from 'react';
import { Link } from 'react-router-dom';
import { FaLaptopCode, FaBrush, FaUserTie, FaFileAlt } from 'react-icons/fa';

// Internship data for mapping
const internshipData = [
  {
    title: 'Paid Internship',
    icon: <FaLaptopCode />,
    description: 'Paid internships involve financial compensation and provide hands-on experience.',
  },
  {
    title: 'Unpaid Internship',
    icon: <FaUserTie />,
    description: 'Unpaid internships offer work experience without financial compensation.',
  },
  {
    title: 'Installment Internship',
    icon: <FaFileAlt />,
    description: 'Installment internships are for those who cannot pay the full amount upfront.',
  },
];

const Internship = ({ darkMode }) => {
  return (
    <section id="Internship" className="container-xxl py-5">
      <header className="mb-5 border-bottom text-center">
        <h1><span>&#8621;</span>Internships<span>&#8621;</span></h1>
      </header>

      <div className="row justify-content-center">
        {internshipData.map(({ title, icon, description }, index) => (
          <div className="col-md-4 mb-4" key={index}>
            <div className="card border border-primary shadow-sm rounded" style={{ border: 'none' }}>
              <div className="card-body text-center">
                <div className="mb-3" style={{ fontSize: '3rem' }}>
                  {icon}
                </div>
                <h4 className="card-title">{title}</h4>
                <hr />
                <p className="text-dark">{description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <footer className="text-center">
        <Link
          to={'about-internship'}
          className="btn btn-outline-primary  rounded-pill px-4 py-2"
          style={{
            backgroundColor: '#e3f2fd',
            transition: 'all 0.3s ease-in-out',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#bbdefb')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#e3f2fd')}
        >
          Read more
        </Link>
      </footer>
    </section>
  );
};

export default Internship;
