import React from 'react';

const AboutInternship = () => {
  return (
    <section id="AboutInternship" className="container mt-5 mb-5">

      {/* Paid Internship Section */}
      <section id="paidInternship" className="mb-5 p-4" style={{ backgroundColor: '#f4f8fb', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h1 style={{ color: '#007bff' }}>Paid Internship</h1>
        </header>
        <p>A paid internship offers financial compensation for work, providing hands-on experience and professional networking opportunities. These internships allow you to apply your academic knowledge in real-world scenarios and gain valuable career skills.</p>
        
        <h4>Benefits of a Paid Internship:</h4>
        <ul>
          <li><strong>Compensation:</strong> Earn a salary or stipend that helps support your living expenses.</li>
          <li><strong>Experience:</strong> Gain practical experience in the industry, improving your resume and skillset.</li>
          <li><strong>Mentorship:</strong> Work alongside professionals and receive guidance and feedback from experienced mentors.</li>
          <li><strong>Networking:</strong> Build connections with industry leaders and peers that could open doors for future job opportunities.</li>
          <li><strong>Career Advancement:</strong> In many cases, a paid internship may lead to a full-time job offer after graduation.</li>
        </ul>

        <h4>Skills Developed:</h4>
        <ul>
          <li>Project management and time management skills.</li>
          <li>Technical skills related to the specific field (e.g., coding, design, marketing). </li>
          <li>Teamwork, communication, and problem-solving skills.</li>
        </ul>

        <h4>Courses and Fees</h4>
        <table className="table table-bordered">
          <thead>
            <tr><th>#</th><th>Course</th><th>Fees</th></tr>
          </thead>
          <tbody>
            <tr><td>1</td><td>Web Design</td><td>10,000</td></tr>
            <tr><td>2</td><td>Graphic Design</td><td>10,500</td></tr>
            <tr><td>3</td><td>Frontend Development</td><td>12,000</td></tr>
          </tbody>
        </table>
        
        <h4>Application Process</h4>
        <p>To apply for a paid internship, candidates must:</p>
        <ol>
          <li>Submit a resume highlighting relevant skills and experience.</li>
          <li>Complete an online application form.</li>
          <li>Attend an interview to assess qualifications and fit for the role.</li>
        </ol>

        <h4>Success Story: Intern to Full-time</h4>
        <p>Many companies offer their interns full-time positions after a successful internship. For example, John Doe started as an intern at XYZ Corp. and was offered a full-time developer role after his internship ended. Internships like this are often the first step toward a rewarding career!</p>
      </section>

      {/* Unpaid Internship Section */}
      <section id="unpaidInternship" className="mb-5 p-4" style={{ backgroundColor: '#e9f7fa', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h1 style={{ color: '#17a2b8' }}>Unpaid Internship</h1>
        </header>
        <p>An unpaid internship provides experience without financial compensation, often offering academic credit or other learning opportunities. Unpaid internships are ideal for students or individuals who are looking to gain exposure to an industry without the need for immediate financial compensation.</p>

        <h4>Benefits of an Unpaid Internship:</h4>
        <ul>
          <li><strong>Learning Experience:</strong> Gain industry knowledge and develop professional skills.</li>
          <li><strong>Academic Credit:</strong> Many unpaid internships offer academic credit that counts toward your degree.</li>
          <li><strong>Networking:</strong> Build connections and gain exposure to potential employers.</li>
          <li><strong>Resume Building:</strong> Unpaid internships help you gain experience that enhances your resume and makes you more competitive for paid roles.</li>
        </ul>

        <h4>What to Expect:</h4>
        <p>Unpaid internships generally focus more on learning and less on work output. Your responsibilities might include shadowing professionals, assisting with research, and supporting day-to-day operations. Many unpaid internships provide valuable experience that will prepare you for future roles in the field.</p>

        <h4>Success Story: A Stepping Stone</h4>
        <p>Jane Smith took on an unpaid internship at ABC Marketing. Although she wasn't compensated, the experience she gained in content creation and social media strategy helped her land a paid marketing position right after graduation. Unpaid internships can be an invaluable stepping stone in your career journey!</p>
      </section>

      {/* Installment Internship Section */}
      <section id="installmentInternship" className="mb-5 p-4" style={{ backgroundColor: '#f7f6f2', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h1 style={{ color: '#28a745' }}>Installment Internship</h1>
        </header>
        <p>This model divides the internship into phases, offering flexibility and multiple learning opportunities over time. The installment internship structure allows you to work in manageable phases that fit your schedule and learning needs, making it ideal for people balancing work and education.</p>

        <h4>Benefits of an Installment Internship:</h4>
        <ul>
          <li><strong>Segmented Phases:</strong> Each phase builds on the previous one, providing structured learning.</li>
          <li><strong>Flexible Schedule:</strong> You can work in smaller bursts, making it easier to balance with other commitments.</li>
          <li><strong>Customizable Learning:</strong> Choose specific phases based on your interests and career goals.</li>
          <li><strong>Continuous Feedback:</strong> Receive ongoing feedback after each phase to ensure you're improving and developing.</li>
        </ul>

        <h4>How It Works:</h4>
        <p>The internship is divided into distinct phases. Each phase may last a few weeks to months, focusing on different aspects of the job. You will receive training and assignments for each phase, and at the end of each, you will complete assessments to demonstrate your learning. This format allows for more flexibility, especially for students or working professionals.</p>

        <h4>Courses and Fees</h4>
        <table className="table table-bordered">
          <thead>
            <tr><th>#</th><th>Course</th><th>1st Installment</th><th>2nd Installment</th></tr>
          </thead>
          <tbody>
            <tr><td>1</td><td>Web Design</td><td>5,500</td><td>5,000</td></tr>
            <tr><td>2</td><td>Graphic Design</td><td>5,500</td><td>5,500</td></tr>
          </tbody>
        </table>

        <h4>Success Story: Flexible Learning</h4>
        <p>Mark Johnson completed an installment internship in digital marketing, dividing his time into three phases. He balanced his internship with his academic studies, gaining crucial insights into SEO and content marketing. After completing all phases, he secured a full-time position as a digital marketing specialist.</p>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="mb-5 p-4" style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h2>What Our Interns Say</h2>
        </header>
        <div className="row">
          <div className="col-md-4">
            <blockquote className="blockquote text-center">
              <p>"My internship at XYZ Corp. changed the trajectory of my career. The hands-on experience I gained was invaluable, and I was offered a full-time position after graduation!"</p>
              <footer className="blockquote-footer">John Doe, Full-time Developer at XYZ Corp.</footer>
            </blockquote>
          </div>
          <div className="col-md-4">
            <blockquote className="blockquote text-center">
              <p>"The mentorship I received during my internship was exceptional. I was able to apply my classroom knowledge in real-world projects, which made me more confident in my skills."</p>
              <footer className="blockquote-footer">Jane Smith, Marketing Specialist at ABC Marketing</footer>
            </blockquote>
          </div>
          <div className="col-md-4">
            <blockquote className="blockquote text-center">
              <p>"This internship program gave me a deeper understanding of web development. I was able to build projects that enhanced my portfolio and helped me land a full-time role."</p>
              <footer className="blockquote-footer">Mark Johnson, Digital Marketing Specialist at TechCo</footer>
            </blockquote>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contactSection" className="mb-5 p-4" style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h2>Get in Touch</h2>
        </header>
        <p>If you're interested in applying for any of the internships or have questions, feel free to reach out to us through the contact form below.</p>

        <form>
          <div className="mb-3">
            <label for="name" className="form-label">Full Name</label>
            <input type="text" className="form-control" id="name" placeholder="Enter your full name" />
          </div>
          <div className="mb-3">
            <label for="email" className="form-label">Email address</label>
            <input type="email" className="form-control" id="email" placeholder="Enter your email" />
          </div>
          <div className="mb-3">
            <label for="message" className="form-label">Message</label>
            <textarea className="form-control" id="message" rows="3" placeholder="Your message"></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </section>

    </section>
  );
};

export default AboutInternship;
