import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import navBarlogo from '../assets/img/internstepslogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FaHome, FaServicestack, FaListAlt, FaChalkboardTeacher, FaUsers, FaStar, FaPhoneAlt, FaLock } from 'react-icons/fa';
import '../style/Navbar.css'; // Ensure to import your CSS file here
import AuthModals from './AuthModals'; // Import the AuthModals component

const Navbar = ({ darkMode, toggleTheme }) => {
  // State to manage modal visibility and menu collapse
  const [showModal, setShowModal] = useState(false); // State for the authentication modal
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  // Function to open and close the modals
  const openAuthModal = () => setShowModal(true);
  const closeAuthModal = () => setShowModal(false);

  const textClass = darkMode ? 'text-white' : 'text-primary';
  const bgClass = darkMode ? 'bg-dark' : 'bg-light';
  
  return (
    <>
      {/* Navbar Component */}
      <nav className={`navbar navbar-expand-lg ${bgClass} shadow-sm sticky-top`}>
        <div className="container d-flex justify-content-between">
          {/* Logo Section */}
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img src={navBarlogo} alt="Logo" className="me-2" style={{ width: '40px', height: 'auto',borderRadius:'20px' }} />
            <h5 className={`m-0 ${textClass}`}>Intern Steps</h5>
          </Link>

          {/* Toggler Button for Mobile */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className={textClass} />
          </button>

          {/* Navbar Collapse */}
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {[{ name: 'Home', icon: <FaHome /> },
                { name: 'Service', icon: <FaServicestack /> },
                { name: 'Categories', icon: <FaListAlt /> },
                { name: 'Courses', icon: <FaChalkboardTeacher /> },
                { name: 'Team', icon: <FaUsers /> },
                { name: 'Testimonial', icon: <FaStar /> },
                { name: 'Contact', icon: <FaPhoneAlt /> }
              ].map((item) => (
                <li key={item.name} className="nav-item d-flex align-items-center mx-1">
                  <a href={`#${item.name.toLowerCase()}`} className={`nav-link ${textClass}`}>
                    <span className="me-2">{item.icon}</span>
                    {item.name}
                  </a>
                </li>
              ))}
              
              <li className="nav-item d-flex align-items-center">
                {/* Login button with icon */}
                <button onClick={openAuthModal} className="btn btn-outline-primary rounded-pill">
                  <FaLock className="me-2" />
                  Login
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Modal for Authentication */}
      {showModal && <AuthModals onClose={closeAuthModal} />}
    </>
  );
};

export default Navbar;
