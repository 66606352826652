import React from 'react';

const Footer = ({ darkMode }) => {
  const textClass = darkMode ? 'text-light' : 'text-dark';
  const bgClass = darkMode ? 'bg-dark' : 'bg-light';

  return (
    <footer className={`container-xxl ${bgClass}`}>
      <section className="container-xxl border-top mt-5  ">
        <div className="text-center mt-4">
          <p className={`mb-0 ${textClass}`}>© 2024 Intern Steps. All Rights Reserved.</p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
