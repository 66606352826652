import React, { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './style/styles.css';

// Import components
import Footer from './components/Footer';
import Testimonial from './components/Testimonial';
import Team from './components/Team';
import Service from './components/Service';
import Navbar from './components/Navbar';
import Courses from './components/Courses';
import Categories from './components/Categories';
import Contact from './components/Contact';
import IconCloudDemo from './components/IconCloudDemo';
import ScrollButtons from './components/ScrollButtons';
import SocialMediaIcons from './components/SocialMediaIcons';
import InterviewPreparation from './pages/Interviewpreparation';
import TutorialBox from './pages/TutorialBox';
import AboutInternship from './pages/AboutInternship';
import Internship from './components/Internship';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const location = useLocation();

  const toggleTheme = () => setDarkMode(!darkMode);

  const hideNavbar = ['/interview-preparation', '/tutorial-box', '/about-internship'].includes(location.pathname);
  const hideFooter = ['/interview-preparation', '/tutorial-box', '/about-internship'].includes(location.pathname);

  return (
    <div className={`App ${darkMode ? 'bg-dark text-light' : 'bg-light text-dark'}`}>
      {!hideNavbar && (
        <div className="navbar-container">
          <Navbar darkMode={darkMode} toggleTheme={toggleTheme} />
          <ScrollButtons darkMode={darkMode} toggleTheme={toggleTheme} />
        </div>
      )}
      <div className="app-content dotted-background">
        <Routes>
          <Route path="/" element={<>
            <IconCloudDemo darkMode={darkMode} />
            <Service darkMode={darkMode} />
            <Internship darkMode={darkMode} />
            <Categories darkMode={darkMode} />
            <Courses darkMode={darkMode} />
            <Team darkMode={darkMode} />
            <Testimonial darkMode={darkMode} />
            <Contact darkMode={darkMode} />
          </>} />
          <Route path="/interview-preparation" element={<InterviewPreparation darkMode={darkMode} />} />
          <Route path="/tutorial-box" element={<TutorialBox darkMode={darkMode} />} />
          <Route path="/about-internship" element={<AboutInternship darkMode={darkMode} />} />
        </Routes>
      </div>
      {!hideFooter && <Footer darkMode={darkMode} />}

      <SocialMediaIcons darkMode={darkMode} />
      <ScrollButtons />
    </div>
  );
}

export default App;