import React from 'react';
import testimonial1 from '../assets/img/team-4.jpg';
import testimonial2 from '../assets/img/team-4.jpg';
import testimonial3 from '../assets/img/team-4.jpg';
import testimonial4 from '../assets/img/team-4.jpg';

const Testimonial = () => {

  const testimonials = [
    {
      img: testimonial1,
      name: 'Lucky',
      role: 'Web Developer',
      feedback: 'The courses here are fantastic! I learned so much and improved my skills tremendously.',
      delay: '0.1s',
      borderColor: '#f5c6cb', // Darker red
    },
    {
      img: testimonial2,
      name: 'Himanshu',
      role: 'FullStack Developer',
      feedback: "I'm grateful for the hands-on experience and the supportive instructors. Highly recommended!",
      delay: '0.3s',
      borderColor: '#c3e6cb', // Darker green
    },
    {
      img: testimonial3,
      name: 'Rishabh',
      role: 'Frontend Developer',
      feedback: 'The content was comprehensive and well-structured. It helped me advance my career in data science.',
      delay: '0.5s',
      borderColor: '#bee5eb', // Darker blue
    },
    {
      img: testimonial4,
      name: 'Abhinesh',
      role: 'Frontend Developer',
      feedback: "Amazing courses with practical insights. I've gained skills that are directly applicable to my job.",
      delay: '0.7s',
      borderColor: '#ffeeba', // Darker yellow
    }
  ];

  return (
    <section id='testimonial' className="container-xxl  py-5 ">
      <header className="mb-5 mt-4 border-bottom border-1  text-center wow fadeInUp" data-wow-delay="0.1s">
        <h1 className="" >
          <span>&#8621;</span>What Our Students Say!<span>&#8621;</span>
        </h1>
      </header>
      <div className="row g-4 justify-content-center ">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`col-lg-3 col-md-4 col-sm-6 wow `}
            data-wow-delay={testimonial.delay}
          >
            <div
              className="border border-primary card text-center p-4 rounded-lg border border-primary d-flex flex-column align-items-center text-primary"
              style={{
                backgroundColor: testimonial.cardColor,
                cursor: 'pointer',
                height: '100%',
                width: '100%',
                overflow: 'hidden', // Ensures that child elements do not overflow the card
                /* Hard shadow effect */

              }}
            >
              <div className="image-container mb-3 text-primary" style={{ width: '80px', height: '80px', overflow: 'hidden', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img className="img-xxl rounded-circle text-primary" src={testimonial.img} alt={testimonial.name} style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
              </div>
              <div className="card-body d-flex flex-column justify-content-center">
                <h5 className="card-title mb-2 " style={{ fontSize: '1rem', fontWeight: 'bold' }}>{testimonial.name}</h5>
                <small className=" text-primary" style={{ fontSize: '0.8rem' }}>{testimonial.role}</small>
                <p className="card-text mt-3" style={{ fontSize: '0.9rem', lineHeight: '1.4' }}>{testimonial.feedback}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonial;
