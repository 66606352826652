import React from 'react';
import team1 from '../assets/img/team-1.jpg';
import team2 from '../assets/img/team-2.jpg';
import team3 from '../assets/img/team-3.jpg';
import team4 from '../assets/img/team-4.jpg';
// Check if the image is fine and has proper dimensions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const teamMembers = [
  { img: team1, name: 'Ram', role: 'Web Development Instructor', icon: faCode },
  { img: team2, name: 'Nishu', role: 'DSA Instructor', icon: faCode },
  { img: team3, name: 'Prashant', role: 'Cyber security', icon: faCode },
  { img: team4, name: 'Ravi', role: 'Full-Stack Instructor', icon: faCode }
];

const Team = () => {
  return (
    <section id='team' className="container-xxl text-center py-5">
      <header className="mb-5 mt-4 border-bottom">
        <h1>
          <span>&#8621;</span>Expert Instructors<span>&#8621;</span>
        </h1>
      </header>
      <div className="row g-4 justify-content-center text-primary">
        {teamMembers.map((member, index) => (
          <article
            key={index}
            className="col-lg-3 col-md-4 col-sm-6"
          >
            <div
              className="team-item p-3 rounded d-flex flex-column align-items-center justify-content-center border border-primary"
              style={{
                backgroundColor: 'rgb(255 255 255)',
                /* Hard shadow effect */
              }}
            >
              <div className="image-container rounded-circle border border-primary overflow-hidden d-flex justify-content-center align-items-center mb-3" style={{ width: '100px', height: '100px' }}>
                <img
                  src={member.img}
                  alt={member.name}
                  className="img-fluid"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>
              <div className="text-center">
                <div className="mb-3">
                  <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-primary mx-1">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
                <h5 className="mt-3 mb-0">{member.name}</h5>
                <small><FontAwesomeIcon icon={member.icon} className="me-2" />{member.role}</small>
              </div>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Team;
