import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3Alt, faJs, faReact, faNodeJs, faPython } from '@fortawesome/free-brands-svg-icons';

// Import the tutorials data from the JSON file
import tutorialsData from '../data/tutorials.json';

const TutorialBox = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTutorial, setSelectedTutorial] = useState(null);

    // List of technologies with respective icons and colors
    const technologies = [
        { name: 'HTML', icon: faHtml5, color: '#E34F26' },
        { name: 'CSS', icon: faCss3Alt, color: '#1572B6' },
        { name: 'JavaScript', icon: faJs, color: '#F7DF1E' },
        { name: 'React', icon: faReact, color: '#61DAFB' },
        { name: 'Node.js', icon: faNodeJs, color: '#68A063' },
        { name: 'Python', icon: faPython, color: '#306998' },
    ];

    // Filter the technologies based on the search query
    const filteredTechnologies = technologies.filter(tech =>
        tech.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Handle search functionality for the menu
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleCardClick = (techName) => {
        // Find the tutorial matching the technology
        const tutorial = tutorialsData.find(tutorial =>
            tutorial.title.toLowerCase().includes(techName.toLowerCase())
        );
        setSelectedTutorial(tutorial); // Set the selected tutorial
    };

    return (
        <div className="container-fluid" style={{ paddingBottom: 0 }}>
            {/* Navbar with Bottom Border and Sticky */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ borderBottom: '2px solid #ddd', position: 'sticky', top: 0, zIndex: 1000 }}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">Tutorial Box</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="row flex-nowrap">
                {/* Sidebar with Sticky */}
                <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 sidebar" style={{ borderRight: '2px solid #ddd', position: 'sticky', top: '56px', zIndex: 500 }}>
                    <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-dark min-vh-100">
                        <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                            <span className="fs-5 d-none d-sm-inline">Menu</span>
                        </a>

                        {/* Search Input */}
                        <input
                            type="text"
                            className="form-control form-control-sm mb-3"
                            placeholder="Search for a technology..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />

                        {/* Technologies List */}
                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                            {filteredTechnologies.map((tech) => (
                                <li key={tech.name} className="nav-item" style={{ borderBottom: '1px solid #ddd', width: '100%' }}>
                                    <a
                                        href="#"
                                        className="nav-link align-middle px-0"
                                        onClick={() => handleCardClick(tech.name)}
                                    >
                                        <FontAwesomeIcon icon={tech.icon} className="fs-4" style={{ color: tech.color }} />
                                        <span className="ms-1 d-none d-sm-inline">{tech.name}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Content Area */}
                <div className="col py-3" style={{ minHeight: '100vh' }}>
                    {selectedTutorial ? (
                        <div>
                            <h2>{selectedTutorial.title}</h2>
                            <p>{selectedTutorial.description}</p>
                            <div dangerouslySetInnerHTML={{ __html: selectedTutorial.htmlContent }} />
                        </div>
                    ) : (
                        <p className="text-center">Select a technology to view the tutorial.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TutorialBox;
