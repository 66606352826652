import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faLinkedin, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIcons = () => {

  const socialMedia = [
    { icon: faYoutube, url: 'https://www.youtube.com/channel/UCjRRL1aKFWhwDwVTeP0JLfg', color: '#FF0000' },
    { icon: faLinkedin, url: 'https://www.linkedin.com/in/intern-steps-22ba95325/', color: '#0A66C2' },
    { icon: faInstagram, url: 'https://www.instagram.com/internsteps4u/', gradient: 'linear-gradient(45deg, #FCAF45 0%, #F56040 25%, #C13584 50%, #833AB4 75%, #405DE6 100%)' },
    { icon: faWhatsapp, url: 'https://wa.me/919027587069?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services.', color: '#25D366' },
  ];

  return (
    <div className="social-media-icons">
      {socialMedia.map((social, index) => (
        <a key={index} href={social.url} target="_blank" rel="noopener noreferrer" className="social-icon" style={{ background: social.gradient || social.color, }}><FontAwesomeIcon icon={social.icon} className="icon" /> </a>))}

      <style jsx>{`
        .social-media-icons {
          position: fixed;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .social-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px; /* Size of the circle */
          height: 40px;
          border-radius: 50%; /* Makes the icon circular */
          color: white;
          font-size: 24px;
          margin-bottom: 10px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          text-decoration: none;
        }

        .social-icon:hover {
          transform: scale(1.1);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
        }
      `}</style>
    </div>
  );
};

export default SocialMediaIcons;
