import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import '../style/Modal.css'; // Ensure to add your modal styling

const AuthModals = ({ onClose }) => {
    const [showLogin, setShowLogin] = useState(true); // State to track which modal to show
    const [loginData, setLoginData] = useState({ username: '', password: '' }); // Login form data
    const [registerData, setRegisterData] = useState({ username: '', email: '', password: '', confirmPassword: '' }); // Register form data
    const [showClearButton, setShowClearButton] = useState(false); // State to control visibility of the Clear button

    const toggleModal = () => {
        setShowLogin(!showLogin); // Toggle between login and registration modals
    };

    const handleLoginChange = (e) => {
        const { id, value } = e.target;
        setLoginData(prev => ({ ...prev, [id]: value }));
        setShowClearButton(true); // Show the Clear button when there is input
    };

    const handleRegisterChange = (e) => {
        const { id, value } = e.target;
        setRegisterData(prev => ({ ...prev, [id]: value }));
        setShowClearButton(true); // Show the Clear button when there is input
    };

    const clearLoginFields = () => {
        setLoginData({ username: '', password: '' });
        setShowClearButton(false); // Hide the Clear button after clearing
    };

    const clearRegisterFields = () => {
        setRegisterData({ username: '', email: '', password: '', confirmPassword: '' });
        setShowClearButton(false); // Hide the Clear button after clearing
    };

    return (
        <div className="modal-backdrop">
            <div className="modal-container">
                <div className="modal-header">
                    <h5 className="modal-title text-primary">{showLogin ? 'Login' : 'Register'}</h5>
                    <button onClick={onClose} aria-label="Close" style={{border:'0px' }}>&#10005;</button>
                </div>
                <div className="modal-body">
                    {showLogin ? (
                        <form>
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input
                                    type="text"
                                    id="username"
                                    value={loginData.username}
                                    onChange={handleLoginChange}
                                    placeholder="Enter your username"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={loginData.password}
                                    onChange={handleLoginChange}
                                    placeholder="Enter your password"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg w-100 mt-4 shadow-sm rounded-pill"
                            >
                                Login
                            </button>
                            {showClearButton && ( // Conditionally render the Clear button
                                <button
                                    type="button"
                                    className="btn btn-clear mt-2 w-100" // Use the custom class
                                    onClick={clearLoginFields}
                                >
                                  
                                </button>
                            )}
                        </form>
                    ) : (
                        <form>
                            <div className="mb-3">
                                <label htmlFor="register-username" className="form-label">Username</label>
                                <input
                                    type="text"
                                    id="register-username"
                                    value={registerData.username}
                                    onChange={handleRegisterChange}
                                    placeholder="Enter your username"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={registerData.email}
                                    onChange={handleRegisterChange}
                                    placeholder="Enter your email"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="register-password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    id="register-password"
                                    value={registerData.password}
                                    onChange={handleRegisterChange}
                                    placeholder="Enter your password"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
                                <input
                                    type="password"
                                    id="confirm-password"
                                    value={registerData.confirmPassword}
                                    onChange={handleRegisterChange}
                                    placeholder="Confirm password"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg w-100 mt-4 shadow-sm rounded-pill"
                            >
                                Register
                            </button>
                            {showClearButton && ( // Conditionally render the Clear button
                                <button
                                    type="button"
                                    className="btn btn-clear mt-2 w-100" // Use the custom class
                                    onClick={clearRegisterFields}
                                >
                                  
                                </button>
                            )}
                        </form>
                    )}
                    <div className="mt-3 text-center">
                        {showLogin ? (
                            <>
                                <div>
                                    <a href="/forgot-password" className="text-primary" onClick={onClose}>
                                        Forgot Password?
                                    </a>
                                </div>
                                <p className='mt-5 text-success'>
                                    Don't have an account?
                                    <button
                                        className="btn btn-link text-primary fw-bold px-2 py-1"
                                        onClick={toggleModal}
                                    >
                                        Register
                                    </button>
                                </p>
                            </>
                        ) : (
                            <p className='text-success'>
                                Already have an account?
                                <button
                                    className="btn btn-link text-primary fw-bold px-2 py-1 "
                                    onClick={toggleModal}
                                >
                                    Login
                                </button>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthModals;
